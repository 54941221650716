<template>

    <div class="box is-centered login-box">
        <div class="container has-text-centered">
            <img src="@/assets/img/imperiya_sumok_login.png" alt="Placeholder image">
            <h2 class="subtitle">Личный кабинет обучающегося</h2>
        </div>
        <br/>
        <div class="field">
            <label class="label">Логин</label>
            <div class="control">
                <input class="input" type="text" placeholder="Введите логин"
                       v-model="credentials.username" v-on:keypress.enter="doLogin"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Пароль</label>
            <div class="control">
                <input class="input" type="password" placeholder="Введите пароль"
                       v-model="credentials.password" v-on:keypress.enter="doLogin"/>
            </div>
        </div>
        <Checkbox v-model="credentials.rememberMe" inputId="remember-me" :binary="true"/>
        <label for="remember-me" class="ml-2">Запомнить меня</label>
        <button @click="doLogin" id="loginBtn" class="button is-primary is-fullwidth login-btn">Войти</button>

        <div class="modal" v-bind:class="{'is-active': loginError}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message is-info">
                    <div class="message-header">
                        <p>Ошибка входа в систему</p>
                        <button class="delete" @click="loginError = false"></button>
                    </div>
                    <div class="message-body">
                        {{loginErrorStr}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {useSystemStore} from '@/stores/systemStore';
    import {useUserStore} from "@/stores/userStore";
    import axios from 'axios';
    import Checkbox from 'primevue/checkbox';

    export default {
        name: "LoginPanel",
        components: {Checkbox},
        data() {
            return {
                credentials: {
                    username: '',
                    password: '',
                    rememberMe: false
                },
                loginError: false,
                loginErrorStr: ''
            };
        },
        methods: {
            doLogin(){
                const systemStore = useSystemStore();
                const userStore = useUserStore();
                const creds = "grant_type=password&username=" + this.credentials.username
                    + "&password=" + this.credentials.password;

                axios.post(systemStore.loginPath, creds, {
                    headers: {
                        'Authorization': 'Basic cW1hcmstY0d6dEdxMTA6ZDZjZjM1MjU0OTY0',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }).then((response => {
                    userStore.accessToken = response.data.access_token;
                    userStore.accessTokenExpire = response.data.expires_in;
                    userStore.refreshToken = response.data.refresh_token;
                    userStore.isAuthorized = true;

                    axios.get(systemStore.apiServicePath + 'getCurrentStudent', {
                        headers: {'Authorization': 'Bearer ' + userStore.accessToken}
                    }).then((st_resp) => {
                        userStore.user = st_resp.data;
                        this.$router.push('/');
                    });

                })).catch((error) => {
                    if(error.response != null && error.response.status === 400 && error.response.data.error === 'invalid_grant')
                    {
                        this.loginError = true;
                        this.loginErrorStr = 'Введен неверный логин или пароль.';
                    }
                    else
                    {
                        this.loginError = true;
                        const errStr = error.response ? error.response.data : error.message;
                        this.loginErrorStr = "Неизвестная ошибка: " + JSON.stringify(errStr);
                    }
                });

            }
        }
    }
</script>

<style scoped>
    .login-box {
        max-width: 500px;
        margin: 10px;
    }
    .login-btn {
        margin-top: 20px;
    }
</style>