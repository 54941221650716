<template>
    <div>
        <NavigationBar active-item="courses"/>
        <div class="container mt-bar">
            <div class="columns is-centered">
                <div class="column is-half">
                    <Panel v-for="course in courses" :key="course.id" class="m-2">
                        <template #header>
                            <span class="has-text-weight-bold">{{course.name}}</span>
                        </template>
                        <p>{{course.description == null ? "Нет описания" : course.description}}</p>
                        <div class="tags is-justify-content-right mt-2 are-medium">
                            <div class="tag has-tooltip-arrow col-grey" data-tooltip="Количество материалов">
                                <i class="fa-solid fa-book mr-1"></i>&nbsp;
                                <p class="has-text-weight-bold">{{course.articlesCount}}</p>
                            </div>
                        </div>
                        <div class="field">

                            <button class="button is-info" disabled>
                                <p>Прогресс: {{course.articleProgress}}/{{course.articlesCount}}</p>
                            </button>
                            <ConfirmPopup></ConfirmPopup>
                            <button class="button is-success is-pulled-right" @click="toArticles(course)">
                                <span>К материалам</span>
                                <span class="icon">
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </button>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>

        <div class="modal" v-bind:class="{'is-active': error}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message is-info">
                    <div class="message-header">
                        <p>Ошибка</p>
                        <button class="delete" @click="error = false"></button>
                    </div>
                    <div class="message-body">
                        {{errorStr}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import axios from "axios";
    import NavigationBar from "@/components/NavigationBar";
    import Panel from 'primevue/panel';

    export default {
        name: "CoursesView.vue",
        components: {NavigationBar, Panel},
        data() {
            return {
                courses: [],
                error: false,
                errorStr: ''
            }
        },

        async beforeRouteEnter(to, from, next) {
            const systemStore = useSystemStore();
            const userStore = useUserStore();
            var resp = await axios.get(systemStore.apiServicePath + 'getCourses', {
                headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            })
                .catch((err) => {
                    if(err.response.status === 401) next('/login');
                });

            next(vm => vm.setData(resp ? resp.data : {error: 'Ошибка загрузки данных'}))
        },
        methods: {

            // confirmAbadonCourse(event, course) {
            //
            //     this.$confirm.require({
            //         target: event.currentTarget,
            //         message: 'Вы действительно хотите отменить выбор курса?',
            //         accept: () => {
            //             this.toggleCourse(course)
            //         },
            //         reject: () => {
            //
            //         }
            //     });
            // },
            //
            // toggleCourse(course) {
            //     const systemStore = useSystemStore();
            //     const userStore = useUserStore();
            //
            //     axios.post(systemStore.apiServicePath + 'toggleCourse', {id: course.id}, {
            //         headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            //     }).then((response) => {
            //         course.chosen = response.data;
            //     }).catch((err) => {
            //         this.error = true;
            //         this.errorStr = err;
            //     });
            // },

            toArticles(course) {
                this.$router.push({name: 'articlesPage', query: {courseId: course.id}});
            },

            setData(dto)
            {
                if(!dto.error) {
                    this.courses = dto.courses;
                }
                else {
                    this.error = true;
                    this.errorStr = dto.error;
                }
            }
        }
    }
</script>

<style scoped>
    .col-grey {
        background-color: #f3f3f1;
    }
</style>