<template>
  <nav>
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
  </nav>
  <router-view/>
</template>

<style>

  @import 'assets/fontawesome/css/fontawesome.min.css';
  @import 'assets/fontawesome/css/brands.css';
  @import 'assets/fontawesome/css/solid.css';

  /*TODO: Минификация css*/
  @import 'assets/css/bulma.css';
  @import 'assets/css/bulma-lumen.css';
  @import 'assets/css/bulma-tooltip.min.css';

  @import 'assets/css/primevue-theme1.css';

  @import 'assets/css/styles.css';
</style>
