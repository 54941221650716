<template>
    <div>
        <NavigationBar active-item="articles"/>
        <div class="container mt-bar">
            <div class="columns is-centered">
                <div class="column is-full">
                    <FieldSet class="mb-2" :toggleable="true">
                        <template #legend>
                            <span class=" title is-5">{{article.title}}</span>
                        </template>
                        <div id="ckEd" v-html="article.body"/>
                        <!--TODO: Стилизация контента -->
                    </FieldSet>
                    <FieldSet class="mb-2" :toggleable="true">
                        <template #legend>
                            <span class=" title is-5">Приложения</span>
                        </template>

<!--                        TODO: Разделить по категориям-->
<!--                        <div v-for="at in attachmentTypes" v-bind:key="at">-->
<!--                            {{at}}-->
<!--                        </div>-->
                        <div class="columns">
                            <div class="column is-half">
                                <div v-for="(file, index) in article.attachments" v-bind:key="file.id" >
                                        <span class="tag is-link is-light is-medium mb-2" v-if="index % 2 === 0">
                                            <span class="mr-2"><img :src="require('@/assets/img/file_icons/' + file.type + '.png')"/> </span>
                                            <span><a v-bind:href="getAttachLink(file)" target="_blank">{{file.filename}}</a></span>
                                        </span>
                                </div>

                            </div>
                            <div class="column is-half">
                                <div v-for="(file, index) in article.attachments" v-bind:key="file.id" >
                                        <span class="tag is-link is-light is-medium mb-2" v-if="index % 2 !== 0">
                                            <span class="mr-2"><img :src="require('@/assets/img/file_icons/' + file.type + '.png')"/> </span>
                                            <span><a v-bind:href="getAttachLink(file)" target="_blank">{{file.filename}}</a></span>
                                        </span>
                                </div>
                            </div>
                        </div>


                    </FieldSet>
                    <field-set v-if="article.testAllowed"  class="mb-2" :toggleable="true">
                        <template #legend>
                            <span class=" title is-5">Тестирование</span>
                        </template>
<!--                        TODO: Показывать результат вместо кнопки, если тест пройден-->
                        <button class="button is-fullwidth is-primary" @click="redirectToTest">Пройти тест</button>

                    </field-set>
                </div>
            </div>
        </div>


        <div class="modal" v-bind:class="{'is-active': error}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message is-info">
                    <div class="message-header">
                        <p>Ошибка</p>
                        <button class="delete" @click="error = false"></button>
                    </div>
                    <div class="message-body">
                        {{errorStr}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import NavigationBar from "@/components/NavigationBar";
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import axios from "axios";
    import FieldSet from 'primevue/fieldset';


    export default {
        name: "ArticleView",
        components: {NavigationBar, FieldSet},
        data() {
            return {
                article: {},
                attaches: {},
                error: false,
                errorStr: ''
            }
        },
        async beforeRouteEnter(to, from, next) {
            const systemStore = useSystemStore();
            const userStore = useUserStore();
            let path = systemStore.apiServicePath + 'getArticle';
            if(typeof to.query.articleId != "undefined") path = path + '?id=' + to.query.articleId;

            const resp = await axios.get(path, {
                headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            })
                .catch((err) => {
                    if (err.response.status === 401) next('/login');
                });

            next(vm => vm.setData(resp ? resp.data : {error: 'Ошибка загрузки данных'}))
        },


        methods: {
            getAttachLink(file) {
                const systemStore = useSystemStore();
                const userStore = useUserStore();
                return systemStore.fileDownloadPath + file.id + "?access_token=" + userStore.accessToken;
            },
            // extractAttaches() {
            //     let imgs = [];
            //     let vids = [];
            //     let docs = [];
            //     let oths = [];
            //     this.article.attachments.forEach((item) => {
            //         if(['image'].includes(item.type)) imgs.push(item);
            //         else if(['video'].includes(item.type)) vids.push(item);
            //         else if(['pdf', 'msword', 'excel'].includes(item.type)) docs.push(item);
            //         else oths.push(item);
            //     });
            //     this.attaches = {imgs: imgs, vids: vids, docs: docs, oths: oths};
            // },
            redirectToTest(){
                this.$router.push({name: 'testPage', query: {articleId: this.article.id}})
            },
            setData(dto)
            {
                if(!dto.error) {
                    this.article = dto.article;
                }
                else {
                    this.error = true;
                    this.errorStr = dto.error;
                }
            }
        }
    }
</script>

<style scoped>
    @import '@/assets/ckeditor/styles.css';
</style>