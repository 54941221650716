<template>
    <div>
        <NavigationBar active-item="articles"/>
        <div class="container mt-bar">
            <div class="columns is-centered">
                <div class="column is-full">
                    <h1 class="title is-4 has-text-centered mt-3">Тест по материалу "{{articleTitle}}"</h1>
                    <div>
                        <div v-for="(question, qidx) in questions" v-bind:key="question.id" v-bind:header="'Вопрос ' + question.id">
                            <Panel class="m-2">
                                <template #header>
                                    <span class="has-text-weight-bold">{{qidx + 1}}. {{question.title}}</span>
                                </template>
                                <div v-bind:id="'ckEd' + question.id" v-if="question.body" v-html="question.body"></div>
                                <div class="m-2"/>
                                <div v-for="answer in question.answers" v-bind:key="answer.id" class="mb-1">
                                    <Checkbox v-if="question.type === 1" :inputId="'answer' + answer.id"
                                              :value="{'question': question.id, 'answer': answer.id }"
                                              name="answer" v-model="stAnswers" class="mr-2"/>
                                    <RadioButton v-if="question.type === 0" :inputId="'answer' + answer.id"
                                              :value="{'question': question.id, 'answer': answer.id }"
                                              v-bind:name="'answer-q' + question.id" v-model="strAnswers[qidx]" class="mr-2"/>
                                    <label :for="'answer' + answer.id">{{answer.title}}</label>
                                </div>

                                <label v-if="question.type === 2 || question.type === 3" v-bind:for="'txt' + question.id">Введите Ваш ответ ниже:</label>
                                <input type="text" v-if="question.type === 2" v-bind:id="'txt' + question.id" placeholder="Введите Ваш ответ"
                                       v-on:input="event => updateTxtField(question.id, event.target.value)" class="mr-2 inputFullwidth input"/>
                                <textarea v-if="question.type === 3" v-bind:id="'txt' + question.id" placeholder="Введите Ваш ответ"
                                          v-on:input="event => updateTxtField(question.id, event.target.value)" class="mr-2 inputFullwidth textarea" rows="5"/>

                            </Panel>
                        </div>
                        <div class="button is-primary mt-3 is-fullwidth" @click="sendAnswers">Отправить ответы</div>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal" v-bind:class="{'is-active': error}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message is-info">
                    <div class="message-header">
                        <p>Ошибка</p>
                        <button class="delete" @click="error = false"></button>
                    </div>
                    <div class="message-body">
                        {{errorStr}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-bind:class="{'is-active': tCompleted}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message" v-bind:class="{
                    'is-success': tPassed && !needVerification,
                    'is-danger': !tPassed && !needVerification,
                    'is-warning': needVerification}">
                    <div class="message-header">
                        <p v-if="tPassed && !needVerification">Тест пройден!</p>
                        <p v-if="!tPassed && !needVerification">Тест не пройден</p>
                        <p v-if="needVerification">Тест требует проверки менеджером</p>
                    </div>
                    <div class="message-body">
                        <p class="has-text-centered mb-3">
                            <img v-bind:src="require('@/assets/img/' + (needVerification ? 'verif.png' : tPassed ? 'pass.png' : 'notpass.png'))">
                        </p>
                        <p class="has-text-centered"> Ваш результат: {{tMarks}} баллов из 100 </p>
                        <button class="button is-fullwidth mt-3" @click="redirectToArticles">Продолжить</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import NavigationBar from "@/components/NavigationBar";
    import axios from "axios";
    import Panel from 'primevue/panel';
    import Checkbox from 'primevue/checkbox';
    import RadioButton from 'primevue/radiobutton';


    export default {
        name: "TestView",
        components: {NavigationBar, Panel, Checkbox, RadioButton},
        data() {
            return {
                questions: [],
                articleTitle: '',
                articleId: 0,
                stAnswers: [],
                strAnswers: [],
                txtAnswers: {},
                tCompleted: false,
                tPassed: false,
                needVerification: false,
                tMarks: 0,
                error: false,
                errorStr: ''
            }
        },
        async beforeRouteEnter(to, from, next) {
            const systemStore = useSystemStore();
            const userStore = useUserStore();
            let path = systemStore.apiServicePath + 'startTest';
            if(typeof to.query.articleId != "undefined") path = path + '?id=' + to.query.articleId;

            const resp = await axios.get(path, {
                headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            })
                .catch((err) => {
                    if (err.response.status === 401) next('/login');
                });

            next(vm => vm.setData(resp ? resp.data : {error: 'Ошибка загрузки данных'}))
        },

        methods: {
            sendAnswers(){
                const systemStore = useSystemStore();
                const userStore = useUserStore();
                let answers = this.stAnswers.concat(this.strAnswers);

                for(const [key, value] of Object.entries(this.txtAnswers)) {
                    const t = {question: key, textAnswer: value};
                    answers.push(t);
                }

                console.log(JSON.stringify(answers));

                const dto = {articleId: this.articleId, answers: answers};

                axios.post(systemStore.apiServicePath + 'endTest', dto, {
                    headers: {'Authorization': 'Bearer ' + userStore.accessToken
                    }
                }).then((response => {
                    if(response.data) {
                        this.tCompleted = true;
                        this.tPassed = response.data.testPassed;
                        this.tMarks = response.data.marks;
                        this.needVerification = response.data.needVerification;
                    }
                    else
                    {
                        this.error = true;
                        this.errorStr = "Ошибка обработки теста";
                    }

                }));
            },
            updateTxtField(questionId, value) {
                this.txtAnswers[questionId] = value;
            },
            redirectToArticles(){
                this.$router.push({name: 'articlesPage'});
            },
            setData(dto)
            {
                if(!dto.error) {
                    this.questions = dto.questions;
                    this.articleTitle = dto.articleTitle;
                    this.articleId = dto.articleId;
                }
                else {
                    this.error = true;
                    this.errorStr = dto.error;
                }
            }
        }
    }
</script>

<style scoped>
    .inputFullwidth {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
    }


    .input, .textarea {
        box-shadow: inset 0 .0625em .125em rgba(10,10,10,.05);
        background-color: hsl(0,0%,100%);
        border-radius: .375em;
        color: hsl(0,0%,21%);
        font-size: 1rem;
        line-height: 1.5;
        border: 1px solid hsl(0,0%,86%);
        font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif

    }

    .input:focus, .textarea:focus {
        border-color: hsl(229,53%,53%);
        box-shadow: 0 0 0 .125em rgba(72,95,199,.25);
        outline: 0;
    }

    .textarea:not([rows]) {
        max-height: 40em;
        min-height: 8em;
    }

</style>