<template>
    <div>
        <NavigationBar active-item="articles"/>
        <div class="container mt-bar">
            <div class="columns is-centered">
                <div class="column is-full">
                    <Accordion :active-index="0">
                        <AccordionTab v-for="course in courses" v-bind:key="course.id">
                            <template #header>
                                <span class="has-text-weight-bold">{{course.name}}</span>
                            </template>

                            <Timeline :value="course.articles" class="is-align-self-flex-start">
                                <template #marker="slotProps">
                                    <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
                                          :style="{ backgroundColor: slotProps.item.status === 0 ? '#FF9900' : slotProps.item.status === 1 ? '#158CBA' : '#28B62C'}">
                                        <i class="fa" v-bind:class="{'fa-lock': slotProps.item.status === 0,
                                                                     'fa-arrow-right': slotProps.item.status === 1 || slotProps.item.status === 3,
                                                                     'fa-check' : slotProps.item.status === 2}"></i>
                                    </span>
                                </template>
                                <template #content="slotProps">
                                    <Card class="mt-2">
                                        <template #title>
                                            {{slotProps.item.title}}
<!--                                            <span v-if="slotProps.item.status === 2" class="tag mt-4 mr-3 is-success is-light is-pulled-right">-->
<!--                                                Набрано баллов: {{slotProps.item.testMarks}}-->
<!--                                            </span>-->
                                        </template>
                                        <template #subtitle>
                                            Вопросов в тесте: {{slotProps.item.numQuestions}}
                                        </template>
                                        <template #content>

                                            <div class="field">
                                                {{slotProps.item.description ? slotProps.item.description : "Нет описания"}}
                                                <button v-if="slotProps.item.status > 0" class="button mr-2 mt-3 is-success is-pulled-right is-fullwidth-mobile"
                                                        @click="gotoArticle(slotProps.item.id)">
                                                    <span>Просмотреть теорию</span>
                                                    <span class="icon">
                                                        <i class="fa-solid fa-arrow-right"></i>
                                                    </span>
                                                </button>
                                                <button v-if="slotProps.item.status === 1" class="button mr-2 mt-3 is-primary is-pulled-right is-fullwidth-mobile"
                                                        @click="gotoTest(slotProps.item.id)">
                                                    <span>Пройти тест</span>
                                                </button>

                                                <span v-if="slotProps.item.status === 2" class="tag mt-4 mr-3 is-primary is-light is-pulled-right is-fullwidth-mobile">
                                                    Набрано баллов: {{slotProps.item.testMarks}}
                                                </span>

                                                <span v-if="slotProps.item.status === 3" class="tag mt-4 mr-3 is-primary is-light is-pulled-right is-fullwidth-mobile">
                                                    Тест проходит проверку
                                                </span>

                                            </div>
                                            &nbsp;
                                        </template>
                                    </Card>

                                </template>
                            </Timeline>

                            <button class="button is-primary is-fullwidth mt-3 mr-3" v-if="!course.finalTestPassed && !course.finalTestNeedVerification"
                                    :disabled="!course.finalTestAllowed" @click="course.reqFinalTest = true">
                                Пройти итоговый тест
                            </button>
                            <div class="m-3 has-text-centered is-vcentered has-text-weight-bold" v-if="course.finalTestPassed && !course.finalTestNeedVerification">
                                <img v-bind:src="require('@/assets/img/pass.png')">
                                <div>Вы успешно прошли итоговый тест!</div>
                            </div>
                            <div class="m-3 has-text-centered is-vcentered has-text-weight-bold" v-if="course.finalTestNeedVerification">
                                <img v-bind:src="require('@/assets/img/verif.png')">
                                <div>Итоговый тест проверяется...</div>
                            </div>
                            <div class="modal" v-bind:class="{'is-active': course.reqFinalTest}">
                                <div class="modal-background"></div>
                                <div class="modal-content">
                                    <div class="message is-info">
                                        <div class="message-header">
                                            <p>Начать итоговый тест?</p>
                                            <button class="delete" @click="course.reqFinalTest = false"></button>
                                        </div>
                                        <div class="message-body">
                                            <p>Вы прошли все тесты данного курса ({{course.name}}) и можете приступить к выполнению итогового теста.</p>
                                            <p>Также вы можете потренироваться в прохождении, без сохранения результатов.</p>
                                            <p v-if="ftError" class="has-text-centered">
                                                <span  class="tag is-medium is-danger mt-2">{{ftErrorStr}}</span>
                                            </p>
                                            <div v-if="course.finalTestProtected" class="mt-2 mb-2">

                                                <label>
                                                    Пароль:
                                                    <input class="input" type="password" placeholder="Введите пароль"
                                                           v-model="ftPassword" />
                                                </label>
                                            </div>
                                            <p class="has-text-centered">
                                                <button class="button is-primary m-2" @click="startFinalTest(course, false)">Пройти тест</button>
                                                <button class="button m-2" @click="startFinalTest(course, true)">Тренировка</button>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>


        <div class="modal" v-bind:class="{'is-active': error}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="message is-info">
                    <div class="message-header">
                        <p>Ошибка</p>
                        <button class="delete" @click="error = false"></button>
                    </div>
                    <div class="message-body">
                        {{errorStr}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import axios from "axios";
    import NavigationBar from "@/components/NavigationBar";
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import Timeline from 'primevue/timeline'
    import Card from 'primevue/card'

    export default {
        name: "ArticlesView",
        components: {NavigationBar, Accordion, AccordionTab, Timeline, Card},
        data() {
            return {
                courses: [],
                ftPassword: "",
                ftError: false,
                ftErrorStr: "",
                error: false,
                errorStr: ''
            }
        },
        async beforeRouteEnter(to, from, next) {
            const systemStore = useSystemStore();
            const userStore = useUserStore();
            let path = systemStore.apiServicePath + 'getArticles';
            if(typeof to.query.courseId != "undefined") path = path + '?courseId=' + to.query.courseId;

            const resp = await axios.get(path, {
                headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            })
                .catch((err) => {
                    if (err.response.status === 401) next('/login');
                });

            next(vm => vm.setData(resp ? resp.data : {error: 'Ошибка загрузки данных'}))
        },
        methods: {

            gotoArticle(id) {
                this.$router.push({name: 'articlePage', query: {articleId: id}})
            },

            gotoTest(id) {
                this.$router.push({name: 'testPage', query: {articleId: id}})
            },

            startFinalTest(course, trainingMode) {
                const systemStore = useSystemStore();
                const userStore = useUserStore();

                axios.get(systemStore.apiServicePath + "startFinalTest"
                    + "?courseId=" + course.id
                    + "&password=" + this.ftPassword
                    + "&trainingMode=" + trainingMode
                    , {headers: {'Authorization': 'Bearer ' + userStore.accessToken}
                }).then((response) => {
                    if(response.data.error) {
                        this.ftError = true;
                        this.ftErrorStr = response.data.error;
                        return;
                    }
                    userStore.ftData = response.data;
                    this.$router.push('/finaltest');
                })
            },


            setData(dto)
            {
                if(!dto.error) {
                    this.courses = dto.courses;
                }
                else {
                    this.error = true;
                    this.errorStr = dto.error;
                }
            }
        }
    }
</script>

<style scoped>
    .flex {
        display: flex !important;
    }
    .w-2rem {
        width: 2rem !important;
    }
    .h-2rem {
        height: 2rem !important;
    }
    .align-items-center {
        align-items: center !important;
    }
    .justify-content-center {
        justify-content: center !important;
    }
    .text-white {
        color: #fff !important;
    }
    .border-circle {
        border-radius: 50% !important;
    }
    .z-1 {
        z-index: 1 !important;
    }
    .shadow-1 {
        box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014 !important;
    }

</style>