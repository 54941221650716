<template>
    <nav class="navbar is-info is-fixed-top" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <p class="navbar-item has-text-weight-bold qmark-brand mr-2">
                <img src="@/assets/logo.png" class="mr-2"> ИС-Обучение
            </p>
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu"
               @click="navbarMenuVisible = !navbarMenuVisible" v-bind:class="{'is-active': navbarMenuVisible}">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div id="navbarMenu" class="navbar-menu" v-bind:class="{'is-active': navbarMenuVisible}">
            <div class="navbar-start">
                <router-link class="navbar-item" to="/courses" v-bind:class="{'is-active': activeItem === 'courses'}">
                    Выбор курсов
                </router-link>
                <router-link class="navbar-item" to="/articles" v-bind:class="{'is-active': activeItem === 'articles'}">
                    Тестирование
                </router-link>
                <router-link class="navbar-item" to="/tests" v-bind:class="{'is-active': activeItem === 'summary'}">
                    Результаты
                </router-link>
            </div>
            <div class="navbar-end">
                <div class="navbar-item has-text-weight-bold has-dropdown is-hoverable">
                    <a class="navbar-link">
                        <img src="@/assets/img/Portrait_Placeholder.png">&nbsp;
                        {{student.fullNameShort}}
                    </a>
                    <div class="navbar-dropdown">
                        <a class="navbar-item" @click="logout">Выход</a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import axios from "axios";

    export default {
        name: 'NavigationBar',
        data() {
            return {
                navbarMenuVisible: false,
                student: {}
            }
        },
        props: {
            activeItem: {}
        },
        created() {
            const userStore = useUserStore();
            this.student = userStore.user;
        },
        methods: {
            logout() {
                const systemStore = useSystemStore();
                const userStore = useUserStore();
                axios.post(systemStore.apiServicePath + 'logout', {}, {
                    headers: {'Authorization': 'Bearer ' + userStore.accessToken}
                }).then(() => {
                    userStore.accessToken = '';
                    userStore.username = '';
                    userStore.accessTokenExpire = 0;
                    userStore.refreshToken = '';
                    userStore.isAuthorized = false;
                    this.$router.push('/login');
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }
</script>
<style scoped>
    .qmark-brand {
        font-size: larger;
    }
</style>