import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import CoursesView from "@/views/CoursesView";
import ArticlesView from "@/views/ArticlesView";
import ArticleView from "@/views/ArticleView";
import TestView from "@/views/TestView";
import TestsView from "@/views/SummaryView";
import FinalTestView from "@/views/FinalTestView";

const routes = [
  {
    path: '/',
    name: 'mainPage',
    redirect: '/articles'
  },
  {
    path: '/login',
    name: 'loginPage',
    component: LoginView
  },
  {
    path: '/courses',
    name: 'coursesPage',
    component: CoursesView
  },
  {
    path: '/articles',
    name: 'articlesPage',
    component: ArticlesView
  },
  {
    path: '/article',
    name: 'articlePage',
    component: ArticleView
  },
  {
    path: '/tests',
    name: 'testsPage',
    component: TestsView
  },
  {
    path: '/test',
    name: 'testPage',
    component: TestView
  },
  {
    path: '/finaltest',
    name: 'finalTestPage',
    component: FinalTestView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
