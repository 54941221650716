<template>
  <div class="loginView">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-half">
          <LoginPanel />
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import LoginPanel from "@/components/LoginPanel";

export default {
  name: 'LoginView',
  components: {
    LoginPanel

  }
}
</script>
