import {defineStore} from "pinia";

//test api url
//const _apiHost = 'http://localhost:8080/';

//PRODUCTION api url
const _apiHost = 'https://manager.sumki-education.ru/';

export const useSystemStore = defineStore('useSystemStore', {
    state: () => ({
        apiHost: _apiHost,
        loginPath: _apiHost + 'app/rest/v2/oauth/token',
        apiServicePath: _apiHost + 'app/rest/v2/services/qmark_ApiService/',
        fileDownloadPath: _apiHost + 'app/rest/v2/files/'
    }),
    persist: true
});
