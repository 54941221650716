import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import PrimeVueLocale from './assets/js/primevue/ru.json'
import ConfirmationService from 'primevue/confirmationservice';

const pinia = createPinia();
const app = createApp(App);

pinia.use(piniaPluginPersistedState)

app.use(pinia);
app.use(PrimeVue, {
    locale: PrimeVueLocale.ru
});
app.use(ConfirmationService);

app.use(router).mount('#app')
