<template>
    <div>
        <NavigationBar active-item="summary"/>
        <div class="container mt-bar">
            <div class="columns is-centered">
                <div class="column is-full">
                    <Panel class="m-2">
                        <template #header>
                            <span class="has-text-weight-bold">Результаты тестов</span>
                        </template>
                        <DataTable :value="testSummary"
                                   tableStyle="min-width: 50rem"

                                   rowGroupMode="rowspan"
                                   groupRowsBy="course">
                            <Column field="course" header="Курс">
                                <template #body="slotProps">
                                    <div class="is-vcentered">
                                        <p>{{slotProps.data.course}}</p>
                                    </div>
                                </template>
                            </Column>
                            <Column field="title" header="Тест"/>
                            <Column field="time" header="Время прохождения"/>
                            <Column field="marks" header="Оценка"/>
                        </DataTable>
                    </Panel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavigationBar from "@/components/NavigationBar";
    import Panel from 'primevue/panel';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import {useSystemStore} from "@/stores/systemStore";
    import {useUserStore} from "@/stores/userStore";
    import axios from "axios";

    export default {
        name: "TestsView",
        components: {NavigationBar, Panel, DataTable, Column},
        data() {
            return {
                testSummary: [{course: "Основной курс", title: "Основы продаж чемоданов", time: "00:05:00", marks: 89},
                    {course: "Основной курс", title: "Основы продаж сумок", time: "00:06:00", marks: 90}]
            }
        },
        async beforeRouteEnter(to, from, next) {
            const systemStore = useSystemStore();
            const userStore = useUserStore();
            var resp = await axios.get(systemStore.apiServicePath + 'getTestSummary', {
                headers: {'Authorization': 'Bearer ' + userStore.accessToken}
            })
                .catch((err) => {
                    if(err.response.status === 401) next('/login');
                });

            next(vm => vm.setData(resp ? resp.data : {error: 'Ошибка загрузки данных'}))
        },
        methods: {
            setData(dto)
            {
                if(!dto.error) {
                    this.testSummary = dto.testSummary;
                }
                else {
                    this.error = true;
                    this.errorStr = dto.error;
                }
            }
        }
    }
</script>

<style scoped>

</style>