import {defineStore} from "pinia";

export const useUserStore = defineStore('useUserStore', {
    state: () => ({
        username: '',
        accessToken: '',
        accessTokenExpire: 0,
        refreshToken: '',
        isAuthorized: false,
        ftData: null,
        user: {
            id: null,
            firstName: '',
            lastName: '',
            patronymic: '',
            fullNameShort: '',
            fullNameExpanded: ''
        }
    }),
    persist: true
});